<template>
  <div
    :class="[
      isShowDiscountData && 'isShowDiscountData',
      isShowBottomInfo && 'bonuses-top',
      position,
    ]"
    class="cart-extra-opt"
  >
    <div class="left">
      <span
        class="download-pdn"
        @click="saveCartPDF"
      >Сохранить в PDF</span>

      <template v-if="position && position==='top'">
        <span
          v-if="false"
          class="bonuses"
          @click="openBonusesPopup"
        >Использовать бонусы</span>
        <span
          class="promo"
          @click="openPromoModal"
        >У меня есть промокод</span>

        <span class="amount-product">
          {{ amountProductTotal }}
        </span>
      </template>

      <template v-if="position && position === 'bottom'">
        <DellProductsFromCartComponent
          class="cart-page__del-controls"
        />
      </template>
    </div>

    <div class="right">
      <template v-if="isShowBottomInfo">
        <div
          v-if="false"
          class="incomingBonuses"
        >
          {{ incomingBonusesTotal }}
        </div>
        <div class="discount-info">
          <div class="discount-info__top">
            <div class="">Сумма заказа:</div>
            <div class="total-sum">{{ $priceFormat({amount: cartData.subtotal, decimalCount: 0}) }} ₽</div>
          </div>
          <div
            v-if="cartData.discounts.length && isShowDiscountData"
            class="discount-info__middle"
          >
            <div
              v-for="disc in cartData.discounts"
              :key="disc.value"
              class="discount-info__middle-row"
            >
              <div>{{ disc.name }}</div>
              <div class="value">({{ $priceFormat({amount: disc.value, decimalCount: 0}) }} ₽)</div>
            </div>
          </div>
          <div v-if="false">
            <div
              v-if="amountAppliedBonuses"
              class="discount-info__middle-row"
            >
              <div>Использовано:</div>
              <div class="value">{{ bonusesAmount }}</div>
            </div>
          </div>
          <div class="discount-info__bottom">
            <div class="total-msg">Итого:</div>
            <div class="total-sum">{{ $priceFormat({amount: totalAmount, decimalCount: 0}) }} ₽</div>
          </div>
        </div>
      </template>

      <template v-else>
        <div v-if="false">
          <div
            v-if="incomingBonuses"
            class="incomingBonuses"
          >
            {{ incomingBonusesTotal }}
          </div>
        </div>

        <span class="total-msg">Итого: </span>
        <span
          v-if="cartData.discounts.length || cartData.discountValue"
          class="total-disc"
        >{{ $priceFormat({amount: cartData.subtotal, decimalCount: 0}) }} ₽</span>
        <span class="total-sum">{{ $priceFormat({amount: totalAmount, decimalCount: 0}) }} ₽</span>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICheckoutDataCart } from '#sitis/internal/controllers/cart/models/CheckoutData';
import DellProductsFromCartComponent from '~/components/cart/DellProductsFromCartComponent.vue';

const { $priceFormat, $handleWordDeclension, $api, $toast, $ym } = useNuxtApp();
const {
  cartData,
  isShowDiscountData = false,
  position = 'top',
  amountAppliedBonuses = null,
  incomingBonuses = 0
} = defineProps<{
	cartData: ICheckoutDataCart;
	isShowDiscountData?: boolean;
	position?: 'top' | 'bottom';
	amountAppliedBonuses: number | null;
	incomingBonuses?: number;
}>();

const emits = defineEmits<{
	openPromoModal: [];
	openBonusesPopup: [boolean];
}>();

const openPromoModal = () => {
  emits('openPromoModal');
};

const openBonusesPopup = () => {
  emits('openBonusesPopup', true);
};

const saveCartPDF = async () => {
  const res = await $api.agent.get('/cart/export-pdf', {
    responseType: 'blob'
  }).then((res) => res).catch((err) => null);
  if (!res) {
    $toast.error('Ошибка генерации PDF, повторите попытку позднее.');
    return;
  }

  const href = URL.createObjectURL(res._data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', 'cart.pdf');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const bonusesAmount = computed(() => {
  if (amountAppliedBonuses) {
    const word = $handleWordDeclension(amountAppliedBonuses, [
      'бонус',
      'бонуса',
      'бонусов'
    ]);

    return `${$priceFormat({ amount: amountAppliedBonuses })} ${word}`;
  }
  return '';
});
const totalAmount = computed(() => (amountAppliedBonuses ? (cartData.total - amountAppliedBonuses) : cartData.total));
const isShowBottomInfo = computed(() => (
  (cartData.discounts.length && isShowDiscountData) || amountAppliedBonuses) &&
	position === 'bottom');

const amountProductTotal = computed(() => {
  const word = $handleWordDeclension(cartData.itemsCount, [
    'предмет',
    'предмета',
    'предметов'
  ]);

  return `${$priceFormat({ amount: cartData.itemsCount })} ${word}`;
});

const incomingBonusesTotal = computed(() => {
  const bonusesWord = $handleWordDeclension(incomingBonuses || 0, ['бонус', 'бонуса', 'бонусов']);
  const bonusesNumber = $priceFormat({
    amount: incomingBonuses
  });
  return `+ ${bonusesNumber} ${bonusesWord} на счет`;
});
console.log(cartData)
</script>

<style scoped lang="scss">
.cart-extra-opt {
	padding: 24px;
	display: flex;
	align-items: center;
	background-color: var(--light-gray);
	justify-content: space-between;
	gap: 20px;

	&.top {
		& > .left > .amount-product {
			display: none;
		}
	}

	& .right {
		display: flex;
		gap: 16px;
		align-items: center;
	}

	&.bonuses-top {
		& .right {
			align-items: baseline;
		}
	}

	& .total-disc {
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 166.667% */
		color: var(--gray);
		text-decoration: line-through;
	}

	& .total-sum {
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		color: var(--black);
	}

	& > .left {
		gap: 20px;
		display: flex;
		max-width: 370px;
		width: 100%;
		//justify-content: space-between;
		color: var(--silver);
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 110%;
		cursor: pointer;

		& > span {
			white-space: nowrap;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	& .incomingBonuses {
		padding: 6px 16px;
		border-radius: 10px;
		background: #d3e6c6;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.discount-info {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: flex-end;

	& .discount-info__top {
		display: flex;
		align-items: baseline;
		gap: 16px;
	}
}

.discount-info__middle {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.discount-info__value-disc {
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.discount-info__middle-row {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 16px;
	line-height: 110%;

	& .value {
		font-weight: bold;
    white-space: nowrap;
	}
}

.discount-info__bottom {
	display: flex;
	align-items: baseline;
	gap: 16px;
}

.total-msg {
	color: var(--silver);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%; /* 22.4px */
}

@media (max-width: 1380px) {
	.cart-extra-opt {
		flex-direction: column;
		align-items: flex-start;

		& .right {
			width: 100%;
			justify-content: flex-end;
		}
	}
}

@media(max-width: 1024px) {
	.cart-extra-opt {
	}
}

@media (max-width: 860px) {
	.cart-extra-opt {
		padding: 14px 16px;

		&.top {
			flex-direction: row;
			align-items: center;
			margin-bottom: 12px;

			& .left {
				width: initial;
			}

			& > .left > .download-pdn,
			& > .left > .promo,
			& > .left > .bonuses {
				display: none;
			}

			& > .left > .amount-product {
				display: flex;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 140%;
				color: var(--black);
			}

			& .total-msg {
				display: none;
			}
		}
	}
}

@media (max-width: 640px) {
	.cart-extra-opt {
		flex-direction: column;
		gap: 16px;

		&.isShowDiscountData {
			padding: 12px;
		}

		&.bonuses-top .right {
			flex-direction: column;
		}

		& .right {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
		}

		& .total-sum {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 110%;
		}

		& .incomingBonuses {
			padding: 6px;
			max-width: 124px;
		}
	}

	.isShowDiscountData {
		& .right {
			justify-content: flex-start;
			align-items: center;
		}
	}

	.discount-info {
		align-items: flex-start;
		width: 100%;

		& .discount-info__top,
		& .discount-info__middle,
		& .discount-info__middle-row,
		& .discount-info__bottom {
			width: 100%;
			justify-content: space-between;
		}
	}
}
</style>
