<template>
  <div class="cart__page-delete">
    <div
      class="left-del"
      @click="removeAll"
    >
      <label for="selectAll">
        Очистить корзину
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
const { clearCart, getCart } = useSitisCart();

const removeAll = async () => {
  await clearCart();
  await getCart();
};
</script>

<style lang="scss">
.cart__page-delete {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;

	& > .left-del {
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: opacity .2s linear;

		&:hover {
			text-decoration: underline;
		}

		> label {
			color: var(--silver);
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 110%;
			cursor: pointer;
		}
	}

	& > .right-del {
		cursor: pointer;

		> .right-del-cartAll {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #F4F4F4;
			border-radius: 10px;
			transition: opacity .2s linear;

			&:hover {
				opacity: .6;
			}
		}
	}
}

@media (max-width: 659px) {
	.cart__page-delete {
		padding: 0;

	}
}
</style>
