<template>
  <div class="cart-root">
    <div
      v-if="cartData && cartData?.itemsInfo.length > 0"
      class="container"
    >
      <div class="cms-block-stub">
        <h1 class="h2">Корзина</h1>
      </div>
      <div class="cart-page__content">
        <div class="left">
          <CartProductCardExtraOptions
            :cart-data="cartData"
            class="extra-option__top"
            :amount-applied-bonuses="amountAppliedBonuses"

            @openPromoModal="() => isPromoModalOpen = true"
            @openBonusesPopup="onHandlerBonusesPopup"
          />

          <div class="cart-page__products">
            <template
              v-for="(product) in cartData?.itemsInfo"
              :key="`cart-product-${product.id}`"
            >
              <CartProductCardMobile
                v-if="$viewport.isLessThan('md')"
                :cart-item="product"
              />
              <CartProductCard
                v-else
                :cart-item="product"
              />
            </template>
          </div>

          <CartProductCardExtraOptions
            :position="'bottom'"
            :cart-data="cartData"
            :amount-applied-bonuses="amountAppliedBonuses"
            is-show-discount-data
            :incoming-bonuses="incomingBonuses"
            @openPromoModal="() => isPromoModalOpen = true"
          />

          <!--          <CartLogisticDetails-->
          <!--            v-if="userManagerInfo"-->
          <!--            :cart-items="itemsCart"-->
          <!--          />-->

          <div class="cart-content --desc">
            <cms-page
              content-only
              page-name="cart"
            />
          </div>
        </div>

        <div class="right">
          <div class="cart__form">
            <CartOrderForm
              :cart-data="cartData"
              :deliveries-list="deliveriesList"
              :payments-list="paymentsList"
              :used-promo="usedPromo"
              :amount-applied-bonuses="amountAppliedBonuses"

              @openPromoPopup="() => isPromoModalOpen = true"
              @openBonusesPopup="onHandlerBonusesPopup"
            />
            <div class="cart-content --mobile">
              <cms-page

                content-only
                page-name="cart"
              />
            </div>
          </div>
        </div>
      </div>
      <LazyCartPromoModal
        v-if="isPromoModalOpen"
        :total-sum="Number(cartData?.total)"
        @close-promo-modal="closePromoModal"
      />

      <LazyCartDialogBonuses
        v-if="isShowBonusesPopup"
        :order-total-amount="cartData.subtotal"

        @onCloseBonusesPopup="onHandlerBonusesPopup"
        @onApplyBonuses="onChangeBonuses"
      />
    </div>

    <template v-if="cartData && cartData?.itemsInfo.length <= 0">
      <div class="container">
        <div class="cart-page__title-empty">
          <h1 class="h2">В корзине пока что ничего нет </h1>
        </div>

        <div class="cart-page__empty-message h5 semibold">
          Это отличная возможность ее заполнить!
        </div>
        <NuxtLink
          class="btn primary outlined"
          to="/catalog"
        >
          Перейти к товарам
        </NuxtLink>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import CartOrderForm from '~/components/cart/CartOrderForm.vue';
import useSitisCart from '#sitis/composables/useSitisCart';
import { useViewport } from '#imports';
import CartProductCardExtraOptions from '~/components/cart/CartProductCardExtraOptions.vue';
import type {
  ICheckoutDataCart,
  ICheckoutDataPayments
} from '#sitis/internal/controllers/cart/models/CheckoutData';

import type { ICheckoutDataDeliveries } from '#sitis/internal/controllers/cart/models/CartItem';

const CartLogisticDetails = defineAsyncComponent(() => import('~/components/cart/CartLogisticDetails.vue'));
const CartProductCardMobile = defineAsyncComponent(() => import('~/components/cart/CartProductCardMobile.vue'));
const CartProductCard = defineAsyncComponent(() => import('~/components/cart/CartProductCard.vue'));
const $viewport = useViewport();

const {
  getCMSContent
} = useSitisCMSPage();
await useLazyAsyncData("get-cms-content-cart", getCMSContent);

const { getCheckoutData, itemsCart } = useSitisCart();

watch(itemsCart, async () => {
  await getDeliveryAndPaymentMethods();
  if (isCartEmpty.value) {
    document.body.classList.add('empty-cart');
  }
}, {
  deep: true
});

const usedPromo = ref<string>('');
const cartData = ref<ICheckoutDataCart | undefined>();
const deliveriesList = ref<ICheckoutDataDeliveries []>([]);
const paymentsList = ref<ICheckoutDataPayments[]>([]);

const isShowBonusesPopup = ref<boolean>(false);
const amountAppliedBonuses = ref<number | null>(null);
const isPromoModalOpen = ref<boolean>(false);

const getDeliveryAndPaymentMethods = async () => {
  const response = await getCheckoutData()
    .then((res) => res)
    .catch((err: any) => {
      console.log('getCheckoutData:', err);
      return null;
    });

  if (!response) {
    return;
  }

  const { deliveries, cart, payments } = response;

  deliveriesList.value = deliveries;
  cartData.value = cart;
  paymentsList.value = payments;
  usedPromo.value = String(response?.promo || "");
};

const closePromoModal = async () => {
  isPromoModalOpen.value = false;
  await getDeliveryAndPaymentMethods();
};

const onHandlerBonusesPopup = (flag: boolean) => {
  isShowBonusesPopup.value = flag;
};

const onChangeBonuses = (bonusesAmount: number) => {
  amountAppliedBonuses.value = bonusesAmount;
  onHandlerBonusesPopup(false);
};

const isCartEmpty = computed(() => cartData.value && cartData?.value?.itemsInfo.length <= 0);
const incomingBonuses = computed(() => cartData.value?.itemsInfo.reduce((acc, value) => acc + value.incomingBonusesValue, 0));

onMounted(async () => {
  await getDeliveryAndPaymentMethods();

  if (isCartEmpty.value) {
    document.body.classList.add('empty-cart');
  }
});
onBeforeUnmount(() => {
  document.body.classList.remove('empty-cart');
});
</script>

<style scoped lang="scss">
.cart-root {
	padding-top: 45px;

	&:deep(.subscribe-block) {
		margin: 120px 0 0;
	}

	& .h2 {
		margin-bottom: 24px;
	}
}

.cart-content {
	&.--mobile {
		display: none;
	}

	&.--desc {
		display: block;
	}
}

.extra-option__top {
	margin-bottom: 17px;
}

.cart-page__content {
	display: flex;
	gap: 40px;

	> .left {
		flex: 70%;
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	> .right {
		width: 30%;
	}
}

.cart-page__text {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 25px;
	padding-left: 30px;
}

.cart-page__products {
	display: flex;
	flex-direction: column;
	gap: 32px;

	& > div:not(:last-child) {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			height: 1px;
			width: 100%;
			bottom: 0;
			left: 0;
			background: var(--light-gray);
		}
	}
}

.cart__form {
	width: 100%;
}

.cart-page__title-empty {
	margin-bottom: 16px;
}

.cart-page__empty-btn {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 350px;
}

.cart-page__empty-message {
	color: var(--silver);
	margin-bottom: 64px;
}

@media (max-width: 1024px) {
	.cart-page__content {
		gap: 20px;
	}
}

@media (max-width: 860px) {
	.cart-content {
		&.--mobile {
			display: block;
		}

		&.--desc {
			display: none;
		}
	}
	.cart-page__content {
		flex-direction: column;

		> .right {
			width: 100%;
		}
	}
}

@media (max-width: 640px) {
	.cart-page__content {
		> .left {
			gap: 20px;
		}
	}

	.cart-page__text {
		font-size: 14px;
		line-height: 20px;
		padding-left: 0;
	}
}
</style>

<style lang="scss">
.empty-cart {
	& .root-layout__body {

		z-index: 1;
		position: relative;
	}

	& .root-layout__footer {
		position: relative;
		z-index: 2;
		margin-top: -36px !important;
	}
}
</style>
