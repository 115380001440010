<template>
  <div>
    <div class="cart__pay-user-info">
      <div class="cart-form__user-info-title">Оформление заказа</div>
      <div>
        <input
          v-model="orderForm.CustomerForm.name"
          placeholder="ФИО *"
          class="input"
        >
        <div
          v-for="(error, index) in $v.CustomerForm.name.$errors"
          :key="index"
          class="error-valid"
        >
          {{ error.$message }}
        </div>
      </div>

      <div>
        <input
          v-model="orderForm.CustomerForm.phone"
          v-maska
          data-maska="+7(###) ###-##-##"
          placeholder="Телефон *"
          class="input"
        >
        <div
          v-for="(error, index) in $v.CustomerForm.phone.$errors"
          :key="index"
          class="error-valid"
        >
          {{ error.$message }}
        </div>
      </div>

      <div>
        <input
          v-model="orderForm.CustomerForm.email"
          placeholder="E-mail *"
          class="input"
        >
        <div
          v-for="(error, index) in $v.CustomerForm.email.$errors"
          :key="index"
          class="error-valid"
        >
          {{ error.$message }}
        </div>
      </div>
      <CustomSelect
        :active-value="deliveryMethods?.name"
        :select-list="deliveriesList"

        @emitOptions="deliveryMethodHandler"
      />
      <div
        class="order-form__city"
        @click="openSelectCityModal"
      >
        <span
          class="city-name"
        >{{ currentCity }}</span>
        <img src="~/assets/img/svg/select/filter-chevron-down.svg">
      </div>
      <div v-if="deliveryMethods?.class === 'city'">
        <CartOrderDeliveryCity
          :address="orderForm.AddressForm"
          @updateForm="changeDeliveryForm"
        />
      </div>

      <div v-if="deliveryMethods?.class === 'pickup' || deliveryMethods?.class === 'russianpost' ">
        <CartOrderDeliveryPickup />
      </div>

      <CustomSelect
        :active-value="paymentMethod?.name"
        :select-list="paymentsList"

        @emitOptions="paymentMethodHandler"
      />

      <div>
        <input
          v-model="orderForm.AddressForm.comment"
          placeholder="Комментарий к заказу"
          class="input"
        >
      </div>
      <div class="order-popup__mobile">
        <span @click="openPromoPopup">У меня есть промокод</span>
        <span v-if="false" @click="openBonusesPopup">Использовать бонусы</span>
      </div>
      <button
        ref="orderBtn"
        class="cart-submit btn primary"
        :disabled=" isFormSubmiting"
        @click="submit"
      >
        Оформить заказ
      </button>
      <div
        v-if="!isShowOrderbBlock && $viewport.isLessThan('md')"
        class="mobile-order__block"
      >
        <div
          class="btn btn-primary"
          @click="onScrollToOrder"
        >
          Оформить
        </div>
        <div class="info-price">
          <span
            v-if="totalDiscount"
            class="total-disc"
          >{{ $priceFormat({amount: cartData.total, decimalCount: 0}) }} ₽</span>
          <span class="total-sum">{{ $priceFormat({amount: totalAmount, decimalCount: 0}) }} ₽</span>
        </div>
      </div>
      <div class="cart-agree">
        <!--        <input-->
        <!--          id="cart-agree-checkbox"-->
        <!--          v-model="isAgree"-->
        <!--          class="checkbox checkbox-secondary"-->
        <!--          type="checkbox"-->
        <!--        >-->
        <label for="cart-agree-checkbox">
          Оформляя заказ, я подтверждаю согласие на обработку моих персональных данных (№152-ФЗ «О защите персональных
          данных») и принимаю
          <nuxt-link
            to="/politika"
            @click.stop
          >условия пользовательского соглашения
          </nuxt-link>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, minLength, required } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';
import { useIntersectionObserver } from '@vueuse/core';
import { utilsSitisCartOrderForm, utilsSitisGetBodyOrder } from '#sitis/utils';
import CustomSelect from '~/components/UI/CustomSelect.vue';
import { useNuxtApp } from '#app';

import type {
  ICheckoutDataPayments,
  ICheckoutDataDeliveries, ICheckoutDataCart
} from '#sitis/internal/controllers/cart/models/CheckoutData';
import CartOrderDeliveryPickup from '~/components/cart/CartOrderDeliveryPickup.vue';
import useCart from '#sitis/composables/useSitisCart';
import type { ICartOrderForm } from '#sitis/utils/cart/utilsSitisCartOrderForm';
import { appConfigStore } from '#sitis/stores/modules/app-store';
import {useCitySelectModal, useUserCity, useViewport} from '#imports';

const $viewport = useViewport();

const CartOrderDeliveryCity = defineAsyncComponent(() => import('~/components/cart/CartOrderDeliveryCity.vue'));

export interface IOrderCityCart {
	name: 'street' | 'house' | 'flat';
	value: string;
}

const { cartData, paymentsList, deliveriesList, amountAppliedBonuses = null, usedPromo } = defineProps<{
	deliveriesList: ICheckoutDataDeliveries[];
	paymentsList: ICheckoutDataPayments[];
	amountAppliedBonuses: number | null;
	cartData: ICheckoutDataCart;
  usedPromo?: string;
}>();

const emits = defineEmits<{
	openPromoPopup: [];
	openBonusesPopup: [boolean];
}>();

const { $api, $toast, $priceFormat, $ym } = useNuxtApp();
const orderForm = ref<ICartOrderForm>({ ...utilsSitisCartOrderForm() });
const { changeSelectPopupOpen } = useUserCity();
const { openSelectCityModal } = useCitySelectModal();
const { getUser, isLoggedIn } = useSitisAuth();

const deliveryMethods = ref<ICheckoutDataDeliveries>({ ...utilsSitisCartOrderForm().DeliveryForm as ICheckoutDataDeliveries });
const paymentMethod = ref<ICheckoutDataPayments>({ ...utilsSitisCartOrderForm().DeliveryForm as ICheckoutDataPayments });

const isAgree = ref<boolean>(true);
const isFormSubmiting = ref<boolean>(false);
const timeOutSaveOrder = ref<ReturnType<typeof setTimeout>>();
const orderBtn = ref<HTMLButtonElement>();
const isShowOrderbBlock = ref(false);

const $router = useRouter();
const configListStore = appConfigStore();
const { config } = storeToRefs(configListStore);
const { getCart } = useCart();

const rules = {
  CustomerForm: {
    name: {
      required: helpers.withMessage('Обязательно к заполнению', required)
    },

    email: {
      email: helpers.withMessage('Неверный формат', email),
      required: helpers.withMessage('Обязательно к заполнению', required)
    },

    phone: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      minLength: helpers.withMessage('Неверный формат', minLength(17))
    }
  }
};
const $v = useVuelidate(rules, orderForm);

const currentCity = computed(() => config?.value?.city?.model?.name || 'Екатеринбург');

const deliveryMethodHandler = (option: ICheckoutDataDeliveries) => {
  deliveryMethods.value = option;
  orderForm.value.DeliveryForm.id = option.id || 0;
};

const paymentMethodHandler = (option: ICheckoutDataPayments) => {
  paymentMethod.value = option;
  orderForm.value.PaymentForm.id = option.id || 0;
};

const changeDeliveryForm = (props: IOrderCityCart) => {
  orderForm.value.AddressForm[props.name] = props.value;
};

const submitOptionsInit = () => {
  if (deliveryMethods.value?.class === 'pickup' ||
		deliveryMethods.value?.class === 'russianpost') {
    orderForm.value.AddressForm = {
      street: '', // Улица
      house: '', // дом
      flat: '' // квартира
    };

    delete orderForm.value.DeliveryForm.address;
  }

  orderForm.value.AddressForm.city_name = currentCity.value;
  orderForm.value.DeliveryForm.city = currentCity.value;
};

const initOrderForm = () => {
  const saveOrderForm: ICartOrderForm = JSON.parse(localStorage.getItem('save-order-form') || '{}');

  if (Object.keys(saveOrderForm).length <= 0) {
    deliveryMethods.value = deliveriesList?.[0];
    paymentMethod.value = paymentsList?.[1];
    return;
  }

  deliveryMethods.value.id = saveOrderForm.DeliveryForm?.id || 0;
  deliveryMethods.value.name = deliveriesList.find((t) => t.id === saveOrderForm.DeliveryForm.id)?.name || 'Выбрать';
  deliveryMethods.value.class = deliveriesList.find((t) => t.id === saveOrderForm.DeliveryForm.id)?.class || '';

  paymentMethod.value.id = saveOrderForm.PaymentForm?.id || 6;
  paymentMethod.value.name = paymentsList.find((t) => t.id === saveOrderForm.PaymentForm.id)?.name || 'Выбрать';

  orderForm.value = { ...saveOrderForm };
};

const submit = async (): Promise<boolean> => {
  const isFormCorrect = await $v.value.$validate();
  if (!isFormCorrect) {
    return false;
  }

  submitOptionsInit();
  const newForm = await utilsSitisGetBodyOrder(orderForm.value);

  if (amountAppliedBonuses) {
    newForm.use_bonuses = true;
    newForm.use_bonuses_value = amountAppliedBonuses;
  }

  isFormSubmiting.value = true;

  const responseCreateOrder = await $api.agent
    .post('checkout/submit', {
      body: {
        ...newForm,
        promo: usedPromo || ""
      }
    })
    .then((res) => res._data)
    .catch((err) => {
      if (err?.response?.status === 500) {
        return {
          error:
						'Произошла ошибка при оформлении заказа. Пожалуйста, свяжитесь с нашим консультантом'
        };
      }
      return { error: err.response?._data?.message || err.response?._data?.[0]?.message || '' };
    });

  if (responseCreateOrder?.error) {
    $toast.error(responseCreateOrder?.error);
    isFormSubmiting.value = false;
    return false;
  }

  isFormSubmiting.value = false;
  await getCart();

  if (isLoggedIn.value) {
    await getUser();
  }

  localStorage.removeItem('save-order-form');
  const successOrder = useCookie('success-order');
  successOrder.value = JSON.stringify(responseCreateOrder);

  if (typeof $ym === 'function') {
    $ym('reachGoal', 'order_success');
  }

  await $router.push('/cart/success');
  return true;
};

const openPromoPopup = () => {
  emits('openPromoPopup');
};
const openBonusesPopup = () => {
  emits('openBonusesPopup', true);
};
watch(() => orderForm.value, (newVal, oldValue) => {
  const initForm = utilsSitisCartOrderForm();

  if (JSON.stringify(orderForm.value) === JSON.stringify(initForm)) {
    localStorage.removeItem('save-order-form');
    return;
  }

  clearTimeout(timeOutSaveOrder.value);

  timeOutSaveOrder.value = setTimeout(() => {
    localStorage.setItem('save-order-form', JSON.stringify(orderForm.value));
  }, 500);
}, { deep: true });

const { stop } = useIntersectionObserver(
  orderBtn,
  ([{ isIntersecting }]) => {
    isShowOrderbBlock.value = isIntersecting;
  }
);
const totalAmount = computed(() => (amountAppliedBonuses ? (cartData.total - amountAppliedBonuses) : cartData.total));
const totalDiscount = computed(() => amountAppliedBonuses);

const onScrollToOrder = () => {
  if (orderBtn.value) {
    window.scrollTo({
      top: orderBtn.value.offsetTop - 80,
      behavior: 'smooth'
    });
  }
};

onMounted(() => {
  initOrderForm();
});

onBeforeUnmount(() => {
  stop();
});
</script>

<style scoped lang="scss">
.cart__pay-user-info {
	display: flex;
	flex-direction: column;
	gap: 10px;
	background: var(--light-gray);
	padding: 34px;

	& .select-element {
		border-radius: 5px;
	}
}

.mobile-order__block {
	position: fixed;
	bottom: 0;
	left: 0;
	border-radius: 10px 10px 0 0;
	padding: 15px 20px;
	background: var(--light-gray);
	width: 100%;
	z-index: 25;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > .btn {
		width: fit-content;
	}

	& > .info-price {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	& .total-disc {
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 166.667% */
		color: var(--light-grey);
		text-decoration: line-through;
	}

	& .total-sum {
		font-size: 20px;
		font-weight: 600;
		line-height: 100%;
	}
}

.cart-form__user-info-title {
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 14px;
}

.order-popup__mobile {
	display: none;
}

.cart-form-separator {
	width: 100%;
	height: 1px;
	background: var(--light-gray);
}

.cart-submit {
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 14px 0;
	border-radius: 16px;
}

.order-form__city {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: var(--input-border);
	height: var(--input-height);
	border-radius: var(--input-border-radius);
	padding: 8px 15px 8px 10px;
	cursor: pointer;
	transition: all .2s linear;

	&:hover {
		border-color: var(--text-secondary-silver);
	}

	& > .city-name {
		color: var(--black);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}

.cart-agree {
	width: 100%;
	display: flex;
	cursor: pointer;

	> .checkbox {
		width: 24px;
		min-width: 24px;
		height: 24px;
	}

	> label {
		color: var(--silver);
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-align: center;

		> a {
			transition: color .2s linear;
			text-decoration: underline;

			&:hover {
				color: var(--dark-gray);
			}
		}
	}
}

@media (max-width: 1024px) {
	.cart__pay-user-info {
		padding: 18px;
	}
}

@media (max-width: 860px) {
	.cart__pay-user-info {
		padding: 32px 20px;
	}
	.cart-submit {
		height: 48px;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 106%;
	}
	.order-popup__mobile {
		margin-top: 24px;
		display: flex;
		gap: 40px;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 110%;
		text-decoration-line: underline;
	}
}
</style>
