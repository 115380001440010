<template>
  <div class="cart-order-delivery-pickup"></div>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
.cart-order-delivery-pickup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-order-pickup-place {

}

</style>
